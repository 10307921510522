'use client'
const customImageLoader = ({ src, width, quality }) => {
  const isCloudinary = src.includes('res.cloudinary.com');
  if (isCloudinary) {
    // Cloudinary specific URL transformation
    return `${src}?w=${width}&q=${quality || 75}&auto=format`;
  }

  // Handle local images or other remote images
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  return `${baseUrl}/${src}?w=${width}&q=${quality || 75}`;
};

export default customImageLoader;
