import { useState, useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

function useMagicLinkParams() {
  const searchParams = useSearchParams();
  const [rid, setRid] = useState(null);

  useEffect(() => {
    setRid(searchParams.get('rid'));
  }, [searchParams]);

  return rid;
}

export default useMagicLinkParams;
