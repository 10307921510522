import {
  createPasswordlessCode,
  consumePasswordlessCode,
  getPasswordlessLoginAttemptInfo,
} from 'supertokens-web-js/recipe/thirdpartypasswordless';

export async function isThisSameBrowserAndDevice() {
  return (await getPasswordlessLoginAttemptInfo()) !== undefined;
}

export async function hasInitialMagicLinkBeenSent() {
  return await getPasswordlessLoginAttemptInfo() !== undefined;
}

export async function sendMagicLink(email) {
  try {
    
      let response = await createPasswordlessCode({ email });
      // Magic link sent successfully
      console.log('response: ', response);
      window.alert('Please check your email for the magic link');

  } catch (err) {
    if (err.isSuperTokensGeneralError === true) {
      // Custom error message from the API
      window.alert(err.message);
    } else {
      console.log('Error in magic link login attempt: ', err);
      window.alert('Oops! Something went wrong.');
    }
  }
}

export async function handleMagicLinkClicked() {
  try {
    // Ensure session storage has the required state
    const authState = await getPasswordlessLoginAttemptInfo();
    if (!authState) {
      throw new Error('No valid auth state present in session storage');
    }

    let response = await consumePasswordlessCode();

    if (response.status === 'OK') {
      if (response.createdNewUser) {
        // User sign up success
        console.log('New user added...', response);
      } else {
        // User sign in success
        console.log('Login successful...', response);
      }
      window.location.assign('/');
    } else {
      // Magic link expired or invalid
      window.alert('Login failed. Please try again');
      window.location.assign('/signin');
    }
  } catch (err) {
    if (err.message === 'No valid auth state present in session storage') {
      window.alert(err.message);
      window.location.assign('/signin');
    } else if (err.isSuperTokensGeneralError === true) {
      // Custom error message from the API
      window.alert(err.message);
    } else {
      console.log('Error in magic link consuming attempt: ', err);
      window.alert('Oops! Something went wrong.');
    }
  }
}
