'use client';
import { useState, useEffect } from "react";
import { addFamilyMember } from "@/utils/user_service";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { calculateAgeFromBob } from "@/utils/lm_utilities";
import { CalenderIcon } from "./icons";
const FamilyMemberForm = () => {

    const session = useSessionContext()

    const [formData, setFormData] = useState({
        dob: null,
        gender: 'm',
        name: '',
    });

    const [memberAdded, setMemberAdded] = useState(false)

    const [validations, setValidations] = useState(null);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'dob') {
            // clear error
            if (value) setValidations(null);
        }

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateDoB = () => {
        const dob = formData.dob;
        let isValid = true;
        let validationMsg;
        if (!dob) {
            validationMsg = 'Please select a date of birth';
            isValid = false;
        }
        if (dob && calculateAgeFromBob(dob) > 20) {
            validationMsg = 'Sorry, we only have data for 0-20year olds but soon we’ll have data for all ages';
            isValid = false;
        }
        if (!isValid) {
            setValidations(validationMsg);
        }

        return isValid;
    };

    useEffect(() => {
        if (!session.doesSessionExist) {
            window.location.href = '/signin';
        }
    }, []);

    const submitForm = () => {
        if (!validateDoB()) {
            return
        };
        addFamilyMember(formData)
            .then((res => {
                if (res.status === 200) {
                    setMemberAdded(true);
                    setFormData({
                        dob: new Date().toLocaleDateString('en-CA'),
                        gender: 'm',
                        name: '',
                    });
                }
                else if (res.status === 401 || res.status === 403) {
                    window.location.href = '/signin';
                }
                else {
                    alert('Opps! There was some problem while adding member.')
                    throw new Error(res.statusText);
                }
            }))
            .catch((err) => console.log(err));
    }

    return (
        <div className="app-content-body pb-0">
            <div className="wrapper-md p-0">
                <div className="row">
                    {/* <Col sm="3"></Col> */}
                    <div className="col-12">
                        <form name="FamilyMemberForm" className="form-horizontal form-validation" onSubmit={e => e.preventDefault()}>
                            {
                                memberAdded &&
                                <div ng-show="successMsg" className="alert alert-success" dismiss-on-timeout="250">
                                    You successfully added a family member!
                                </div>
                            }
                            <div  className="cardpanel panel-default p-0 mb-0" style={{ border: "1px solid black" }}>
                                <div className="card-title panel-heading p-3" style={{ border: "1px solid #edf1f2" }}>
                                    <strong>Who are we measuring?</strong>
                                </div>
                                <div className="card-body panel-body p-4">
                                    <div className="row form-group">
                                        <div className="col-4">
                                            <label className="control-label">Add Name</label>
                                        </div>
                                        <div className="col-7">
                                            <input type="text" className="form-control" name="name"
                                                placeholder="Enter someone’s name" autoComplete="off" required
                                                onChange={handleInputChange} value={formData.name} />
                                        </div>
                                    </div>

                                    <div className="line line-dashed b-b line-lg"></div>
                                    <div className="row form-group">
                                        <div className="col-4">
                                            <label className="control-label">Date of Birth</label>
                                        </div>
                                        <div className="col-7 i_measurement-wrapper position-relative">
                                            <div>
                                                <input name="dob" type="date" className={`dob text-dark form-control ${validations && 'error-border'}`} value={formData.dob} style={{ border: "1px solid #E9E4DB !important" }} onChange={handleInputChange} />
                                                {validations && <span className="error-text">{validations}</span>}
                                                <CalenderIcon />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="line line-dashed b-b line-lg"></div>
                                    <div className="row form-group">
                                        <div className="col-3">
                                            <label className="control-label">Gender</label>
                                        </div>
                                        <div className="col-9">
                                            <div className="form-group">
                                                <label className="i-checks">
                                                    <input type="radio" name="gender" value="m" checked={formData.gender === 'm'} onChange={handleInputChange} /><i></i> Male
                                                </label>
                                                &nbsp;&nbsp;
                                                <label className="i-checks">
                                                    <input type="radio" name="gender" value="f" defaultChecked={formData.gender === 'f'} onChange={handleInputChange} /><i></i> Female
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer panel-footer p-4" style={{ border: "1px solid #edf1f2" }}>
                                    <button type="button" onClick={submitForm} className="btn btn-success">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FamilyMemberForm;