import { sendMagicLink } from "../magicLinkHandle";

// var urlBase = process.env.NEXT_PUBLIC_BASE_URL;


import config from '@/localconfig';
const urlBase = config.BASE_URL;

const login = (email) => {
  sendMagicLink(email.email);
};

const signup = (body) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  return fetch(urlBase + '/api/v1/users/signup', requestOptions);
};

const getFamilyMembers = () => {
  return fetch(`${urlBase}/api/v1/members`);
};

const saveMeasurement = (path, body) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return fetch(urlBase + `/api/v1/measurements/${path}`, requestOptions);
};

const addFamilyMember = (body) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return fetch(urlBase + `/api/v1/members`, requestOptions);
};

const removeFamilyMember = (id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${urlBase}/api/v1/members/${id}`, requestOptions);
};

const getMeasurementsOfMember = (memberId) => {
  return fetch(urlBase + `/api/v1/measurements/member/${memberId}`);
};


async function doesEmailExist(email) {
  try {
    const response = await fetch(urlBase + '/api/v1/users/check-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    return data.exists;
  } catch (error) {
    console.error('Error checking email existence:', error);
    return false;
  }
}

export {
  login,
  getFamilyMembers,
  saveMeasurement,
  addFamilyMember,
  signup,
  removeFamilyMember,
  getMeasurementsOfMember,
  doesEmailExist
};
