'use client';
import dynamic from 'next/dynamic';
import { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import { Modal } from '@/components/reactstrap';
import { calculateAgeFromBob, calculateAgeInMonthsWithTwoDates } from '@/utils/lm_utilities';
import { formulateChartUrl } from '@/utils/intermediary_url_service';
import { localStorageMap } from '@/utils/maps';
import { getFamilyMembers } from '@/utils/user_service';
import FamilyMemberForm from '../familyMemberForm';
const BoyIcon = dynamic(() => import('@/components/icons').then((module) => ({ default: module.BoyIcon })),{ ssr: false });
const GirlIcon = dynamic(() => import('@/components/icons').then((module) => ({ default: module.GirlIcon })),{ ssr: false });
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { CalenderIcon } from '@/components/icons';
const Button = dynamic(() => import('reactstrap').then((module) => ({ default: module.Button })));
const ButtonGroup = dynamic(() => import('reactstrap').then((module) => ({ default: module.ButtonGroup })));
const MainForm = () => {
  const session = useSessionContext();
  const formRef = useRef(null);

  const [showFmModal, setShowFmModal] = useState(false);

  const [showDob, setShowDob] = useState(true);

  const [formData, setFormData] = useState({
    // dob: new Date().toLocaleDateString('en-CA'),
    dob: null,
    gender: 'm',
    md: new Date().toLocaleDateString('en-CA'),
    member_id: null,
  });

  const [chartType, setchartType] = useState('weight-charts');

  // eslint-disable-next-line no-unused-vars
  const [userInfo, setUserInfo] = useState({
    email: '',
    id: '',
    token: '',
  });

  const [familyMembersList, setFamilyMembersList] = useState([]);

  const [hideHeadChartType, sethideHeadChartType] = useState(true);
  const [hideBMIChartType, sethideBMIChartType] = useState(false);


  const [validations, setValidations] = useState(null);
  const [datePickerValidation, setDatePickerValidate] = useState('')

  const scrollToForm = () => {
    if (!formRef.current) return;
    formRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const handleAddFamButton = (e) => {
    e.preventDefault();
    setShowFmModal(true);
  };
  const handleClose = () => {
    setShowFmModal(false);
    window.location.reload();
  };

  const handleInputChange = (event) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'dob') {
      // Disable future dates on the calendar input
      const currentDate = new Date().toISOString().split('T')[0];
      if (value > currentDate) {
        target.value = currentDate;
        value = currentDate;
        setDatePickerValidate('Cannot calculate measurements for future dates. Please select a valid date of birth.');
      } else {
        setDatePickerValidate(null);
      }
      let calculated_age = calculateAgeInMonthsWithTwoDates(value, formData.md);
      sethideHeadChartType(calculated_age > 24);
      sethideBMIChartType(calculated_age < 24);
      // clear error
      if (calculateAgeFromBob(value) <= 20) setValidations(null);
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleFamilyMemberChange = (e) => {
    let member = familyMembersList.filter((member) => member.id == e.target.value)[0];
    if (member) {
      setShowDob(false)
      let dob = new Date(member.dob).toLocaleDateString('en-CA');
      let calculated_age = calculateAgeInMonthsWithTwoDates(dob, formData.md);
      sethideHeadChartType(calculated_age > 24);
      sethideBMIChartType(calculated_age < 24);
      setFormData({
        ...formData,
        dob: dob,
        gender: member.gender,
        member_id: member.id,
      });
    }
  };

  const onRadioBtnClick = (chartType) => {
    setchartType(chartType);
  };

  useEffect(() => {
    let presistedDob = window.localStorage.getItem('lm_previous_dob');
    if (session.doesSessionExist) {
      getFamilyMembers()
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 401 || res.status === 403) {
            window.localStorage.setItem('lm_previous_path', window.location.pathname);
            window.location.href = '/signin';
          } else throw new Error(res.statusText);

          return res.json();
        })
        .then((data) => {
          setFamilyMembersList(data);
        })
        .catch((err) => console.log(err));
    }

    if (presistedDob) {
      setFormData({
        ...formData,
        dob: JSON.parse(presistedDob),
      });
    }
  }, []);

  const validateDoB = () => {
    const { dob } = formData;
    let isValid = true;
    let validationMsg;
    if (!dob) {
      validationMsg = 'Please select a date of birth';
      isValid = false;
    }
    if (dob && calculateAgeFromBob(dob) > 20) {
      validationMsg = 'Sorry, we only have data for 0-20year olds but soon we’ll have data for all ages';
      isValid = false;
    }
    if (!isValid) {
      setValidations(validationMsg);
    }

    return isValid;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!validateDoB()) {
      scrollToForm();
      return
    };
    window.localStorage.setItem(localStorageMap[chartType], JSON.stringify(formData));
    // presist DoB
    window.localStorage.setItem('lm_previous_dob', JSON.stringify(formData.dob));
    let calculated_age = calculateAgeInMonthsWithTwoDates(formData.dob, formData.md);
    /*
         Route to appropriate path
         */
    let url = formulateChartUrl(formData, chartType, calculated_age);
    window.location.href = url;
  };

  return (
    <div className="getting-started" ref={formRef}>
      <Modal centered isOpen={showFmModal} onHide={handleClose}>
        <FamilyMemberForm />
        <button  onClick={handleClose} color="btn btn-primary add-family-member-btn">
          Close
        </button>
      </Modal>
      <form>
        {session.doesSessionExist && (
          <div className="row py-2 justify-content-center">
            <div className="col-10 ps-0">
              <select
                type="select"
                id="member"
                name="member_measurement"
                className="form-select form-control h-100"
                onChange={handleFamilyMemberChange}
              >
                <option value="">Let's measure</option>
                {familyMembersList.map((el) => {
                  return (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              
              className="col-1 d-flex flex-direction-coloumn justify-content-center align-items-center ms-1 ps-1 pe-0"
            >
              <button
                onClick={handleAddFamButton}
                className="add-family-member-link"
                style={{ backgroundColor: '#176C5A' }}
              >
                <Image priority   className="img-fluid" src={'/assets/images/icons/addfamily.svg'} alt="add" height="36" width="36" />
              </button>
            </div>
          </div>
        )}
        {showDob && (
          <div className='row'>
            <div className='col-12'>
              <span className="sub-title-chart d-block text-start mb-2">Gender</span>
            </div>
            <div className="d-flex justify-content-center w-100 m-auto" style={{ maxWidth: '600px' }}>
              <div className="d-flex flex-column align-items-center w-100">
                <label className={`main-checkbox m-auto w-100 ${formData.gender === 'm' ? 'checked' : ''}`}>
                  <input
                    type="radio"
                    name="gender"
                    value="m"
                    defaultChecked={formData.gender === 'm'}
                    onChange={handleInputChange}
                  />
                  <div className="d-flex align-items-center flex-column box ">
                    <div className="d-flex align-items-center">
                      <div className="boy-radio circle-bg ml-2 ">
                        <BoyIcon color={formData.gender === 'm' ? '#fff' : '#176c5a'} />
                      </div>
                      <span>Boy</span>
                    </div>
                    <div className="bottom-line" />
                  </div>
                </label>
              </div>

              <div className="d-flex flex-column align-items-center w-100">
                <label className={`main-checkbox m-auto w-100 ${formData.gender === 'f' ? 'checked' : ''}`}>
                  <input
                    type="radio"
                    name="gender"
                    value="f"
                    defaultChecked={formData.gender === 'f'}
                    onChange={handleInputChange}
                  />
                  <div className="d-flex align-items-center flex-column box ">
                    <div className="d-flex align-items-center">
                      <div className="boy-radio circle-bg ml-2">
                        <GirlIcon color={formData.gender === 'f' ? '#fff' : '#176c5a'} />
                      </div>
                      <span>Girl</span>
                    </div>
                    <div className="bottom-line" />
                  </div>
                </label>
              </div>
            </div>
          </div>
        )}
        {showDob && (
          <div className="row py-2">
            <div className="col mb-2">
              <label htmlFor="dob" className='col-12' >
                <span className="sub-title-chart d-block text-start">Date of Birth</span>
              </label>
            </div>
            <div className="col-12 d-flex i_measurement-wrapper position-relative" style={{ justifyContent: 'space-around' }}>
              <input
                id='dob'
                name="dob"
                type="date"
                className={`dob form-control ${(validations || datePickerValidation) && 'error-border'}`}
                defaultValue={formData.dob}
                onChange={handleInputChange}
              />

              <CalenderIcon color={"#fff"} />

              {validations && <span className="error-text">{validations}</span>}
              {datePickerValidation && <span className="error-text">{datePickerValidation}</span>}

            </div>
          </div>
        )}
        <div className="row py-3 radio-toolbar">
        <ButtonGroup className="cardButton-grid">
            <Button
              className="cardButton"
              onClick={() => onRadioBtnClick('weight-charts')}
              active={chartType === 'weight-charts'}
            >
              {/* <section className="weight" ></section> */}
              <div className="circle mb-3">
                <Image priority   src={'/assets/images/icons/weight-green.svg'} alt="weight measurement icon" width="40" height="40" />
              </div>
              <div>Weight</div>
            </Button>
            <Button
              className="cardButton"
              onClick={() => onRadioBtnClick('height-charts')}
              active={chartType === 'height-charts'}
            >
              <div className="circle mb-3">
                <Image priority   src={'/assets/images/icons/measure.svg'} alt="measure icon" width="40" height="40" />
              </div>
              <div>Height</div>
            </Button>
            {!hideBMIChartType && (
              <Button
                className="cardButton"
                onClick={() => onRadioBtnClick('bmi-charts')}
                active={chartType === 'bmi-charts'}
              >
                <div className="circle mb-3">
                  <Image priority   src={'/assets/images/icons/bmi.svg'} alt="bmi measurement icon" width="40" height="40" />
                </div>
                <div>BMI</div>
              </Button>
            )}

            {!hideHeadChartType && (
              <Button
                className="ms-2 cardButton"
                onClick={() => onRadioBtnClick('head-charts')}
                active={chartType === 'head-charts'}
              >
                <div className="circle mb-3">
                  <Image priority   src={'/assets/images/icons/calculator.svg'} alt="head measurement calculate icon" width="40" height="40" />
                </div>
                <div>Head</div>
              </Button>
            )}
          </ButtonGroup>
        </div>
        <div className='row'>
          <div className='col'>
            <button className="btn start-now" onClick={handleFormSubmit}>
              Start Measuring
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MainForm;
