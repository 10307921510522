'use client';
import { useEffect, useState } from 'react';
// import { useRouter, useSearchParams } from 'next/navigation'; // Import useRouter
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
// UI Components
import MainForm from './main/mainForm';
import { seo } from '@/utils/seo';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { handleMagicLinkClicked, isThisSameBrowserAndDevice } from '@/magicLinkHandle';
import boys from '../../public/assets/images/boys-lifemeasure.webp';
import girls from '../../public/assets/images/girls-lifemeasure.webp';
import customImageLoader from './CustomImage';
import useMagicLinkParams from '@/hooks/useMagicLinkParams';
// Dynamically import the icons components
const BmiIcon = dynamic(() => import('@/components/icons').then((module) => ({ default: module.BmiIcon })),{ ssr: false });
const HeadIcon = dynamic(() => import('@/components/icons').then((module) => ({ default: module.HeadIcon })),{ ssr: false });
const HeightIcon = dynamic(() => import('@/components/icons').then((module) => ({ default: module.HeightIcon })),{ ssr: false });
const WeightIcon = dynamic(() => import('@/components/icons').then((module) => ({ default: module.WeightIcon })),{ ssr: false });
const MainComponent = () => {
  const session = useSessionContext();
  const rid = useMagicLinkParams(); //using custom hook for detacting passwordless auth to avoid CLS issue using suspense.

  const [renderAdSlot, setRenderAdSlot] = useState(false);
  

  useEffect(() => {
    setRenderAdSlot(window.innerWidth > 1000);
  }, []);


  useEffect(() => {
    localStorage.removeItem('lm_heigh_data');
    localStorage.removeItem('lm_weight_data');
    localStorage.removeItem('lm_head_data');
    localStorage.removeItem('lm_bmi_data');

    const consumeMagicLinkIfPresent = async () => {
      // const { query } = router;
      // const isMagicLinkPresent = query.rid === 'thirdpartypasswordless';

      const isMagicLinkPresent = rid === 'thirdpartypasswordless';

      if (isMagicLinkPresent) {
        const sameBrowserAndDevice = await isThisSameBrowserAndDevice();
        if (sameBrowserAndDevice) {
          await handleMagicLinkClicked();
        }
      }
    };

    if (!session.doesSessionExist) {
      consumeMagicLinkIfPresent();
    }
  }, [rid, session]);

  useEffect(() => {
    seo(window.location.pathname);
    const handleScroll = () => {
      const homeStartIcon = document.querySelector('.home-start-icon');
      const greenHomeStartIcon = document.querySelector('.green-home-start-icon');

      if (homeStartIcon && greenHomeStartIcon) {
        homeStartIcon.style.top = `${window.scrollY - 738}px`;
        greenHomeStartIcon.style.top = `${window.scrollY - 150 - 738}px`;

        if (window.scrollY >= 990) {
          greenHomeStartIcon.classList.add('stop');
        } else {
          greenHomeStartIcon.classList.remove('stop');
        }

        if (window.scrollY <= 738) {
          homeStartIcon.classList.add('stop-top');
        } else {
          homeStartIcon.classList.remove('stop-top');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <section className="main">
        <div className="row pt-5 pb-5 home-vm-vid">
        </div>
        <div className='row'>
          <div className="hero d-flex justify-content-center align-items-center flex-column">
            <div className="content-container position-relative">
              <h1 className="banner-heading mb-5">
                Curious about how you measure <br className="d-none d-md-block" /> up with others?
              </h1>
              <span>Start your journey</span>
              <div className="dropdownicon text-white position-relative mb-5">
                <div className="down-arrows">
                  <span></span>
                </div>
              </div>
              <div className="cartoon-information">
                <MainForm />
              </div>
              <Image priority   className="lines" src='/assets/images/lines.svg' alt="lines" height="20" width="200" />

              <div className="home-icons">
                <div className="home-icons-top">
                  <div className="home-start-icon">
                    <Image priority   src='/assets/images/icons/animateIcon.svg' alt="lm" className="full" height="50" width="50" />
                  </div>
                </div>
                <div className="home-icons-bottom">
                  <div className="green-home-start-icon">
                    <svg
                      width="90"
                      height="90"
                      viewBox="0 0 90 90"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="full"
                    >
                      <circle cx="45" cy="45" r="45" fill="#25AF9B" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M52.0646 29.009C52.0646 29.009 52.3716 24.1878 48.9472 21.8033C45.5227 19.4189 41.3409 22.5345 41.3409 22.5345L37.3185 31.2203L42.2506 33.2885L41.0039 35.8851L36.1666 33.7858L33.2024 39.6914L37.734 41.9052L36.5177 44.2193L31.684 42.015L28.1626 48.4982L32.7613 50.7099L31.7493 53.0176L26.905 50.8136C26.905 50.8136 22.5791 58.0345 22.1684 61.1109C21.7576 64.1874 23.4967 64.8856 24.8149 66.4671C26.1727 68.0963 30.7369 69.3585 30.7369 69.3585L52.0646 29.009Z"
                        fill="#fff"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M41.9852 57.0429L41.9852 62.1413H44.2304V57.1251L51.0658 57.23V62.1413H53.311V57.3186L60.1464 57.4216V62.1413H62.3916V57.5042H67.4972L69.7923 62.9142L67.4972 69.3545H30.7754L37.6607 56.989L41.9852 57.0429Z"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="getting-started-section">
        <div className="row py-3">
          <h2>It's just simple</h2>
        </div>
        <div className="row middle-row max-width layout-align-center-center">
          <div className='col-6 col-lg-3'>
            <span className="icon-home">
              <Image priority   src='/assets/images/icons/clock.svg' alt="results" width="130" height="139" />
            </span>
            <h3>Instant Results</h3>
          </div>
          <div className='col-6 col-lg-3'>
            <span className="icon-home">
              <Image priority   src='/assets/images/icons/compare.svg' alt="compare" width="130" height="139" />
            </span>
            <h3>Compare</h3>
          </div>
          <div className='col-6 col-lg-3'>
            <span className="icon-home">
              <Image priority   src='/assets/images/icons/track.svg' alt="track" width="130" height="139" />
            </span>
            <h3>Track Progress</h3>
          </div>
          <div className='col-6 col-lg-3'>
            <span className="icon-home">
              <Image priority   src={'/assets/images/icons/message-green.svg'} alt="share" width="130" height="139" />
            </span>
            <h3>Share Stories</h3>
          </div>
        </div>
      </section>

      <section id="chart-section" className="chart-section p-xs-2 p-lg-4">
        <div className="row py-3 mt-5 mt-lg-0">
          <h2>Choose A Chart</h2>
        </div>
        <div className="row max-width layout-align-center-center">
          <div className='col-12'>
            <div className="card px-3 px-md-3 px-lg-5 boys">
              <Image priority   loader={customImageLoader} placeholder='blur' className="card-img" alt="boys" src={boys} />
              <div className="card-body">
                <h3>Boys</h3>
                <div className="card-links-wrapper">
                  <Link className="card-links py-2 px-3 px-md-3" prefetch={false} href="/height-charts/boys">
                    <HeightIcon color={'#25AF9B'} />
                    Height
                  </Link>
                  <Link className="card-links py-2 px-3 px-md-3" prefetch={false} href="/weight-charts/boys">
                    <WeightIcon color={'#25AF9B'} />
                    Weight
                  </Link>
                  <Link className="card-links py-2 px-3 px-md-3" prefetch={false} href="/bmi-charts/boys">
                    <BmiIcon color={'#25AF9B'} />
                    BMI
                  </Link>
                  <Link className="card-links py-2 px-3 px-md-3" prefetch={false} href="/head-charts/boys">
                    <HeadIcon color={'#25AF9B'} />
                    Head
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className="card px-3 px-md-3 px-lg-5 girls">
              <Image priority   loader={customImageLoader} placeholder='blur' className="card-img object-lg-contain" alt="girls" src={girls} />
              <div className="card-body">
                <h3>Girls</h3>
                <div className="card-links-wrapper">
                  <Link className="card-links py-2 px-3 px-md-3" prefetch={false} href="/height-charts/girls">
                    <HeightIcon color={'#25AF9B'} />
                    Height
                  </Link>
                  <Link className="card-links py-2 px-3 px-md-3" prefetch={false} href="/weight-charts/girls">
                    <WeightIcon color={'#25AF9B'} />
                    Weight
                  </Link>
                  <Link className="card-links py-2 px-3 px-md-3" prefetch={false} href="/bmi-charts/girls">
                    <BmiIcon color={'#25AF9B'} />
                    BMI
                  </Link>
                  <Link className="card-links py-2 px-3 px-md-3" prefetch={false} href="/head-charts/girls">
                    <HeadIcon color="#25AF9B" />
                    Head
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <script
                type="application/ld+json"
                id="schema-script"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                      "@context": "https://schema.org",
                      "@type": "WebPage",
                      "name": "LifeMeasure",
                      "description": "LifeMeasure offers growth charts and calculators for the whole family.",
                      "url": `${process.env.NEXT_PUBLIC_BASE_URL}`,
                      "publisher": {
                        "@type": "Organization",
                        "name": "LifeMeasure",
                        "logo": {
                          "@type": "ImageObject",
                          "url": `${process.env.NEXT_PUBLIC_BASE_URL}/assets/images/logos/LM-logo.svg`
                        },
                        "sameAs": [
                          "https://www.facebook.com/lifemeasure",
                          "https://twitter.com/lifemeasure"
                        ]
                      },
                      "mainEntityOfPage": {
                        "@type": "WebPage",
                        "@id": `${process.env.NEXT_PUBLIC_BASE_URL}`
                      }})
                }}
            />

      </section>
    </>
  );
};

const Main = () =>  <MainComponent />;

export default Main;
